<!-- THIS CODE IS NOT WORKING. IT NEEDS DEBUGGING -->
<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 col-sm-7">
        <router-link
          :to="{ name: 'TeamManage' }"
          class="btn btn-link"
        >
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            class="me-1"
          />
          Back to Squad</router-link
        >

        <!-- Display Team Information -->
        <h3
          v-if="team"
          class="mb-4"
        >
          <div class="league-side d-flex flex-column align-items-end">
            <small class="text-muted">{{ league && league.shortName }}</small>
          </div>
          <img
            v-if="league"
            :src="league"
            alt="League Logo"
            class="me-0 mb-1"
            style="width: 50px; height: 50px"
          />
          {{ leagueName }} - {{ clubName }} - {{ team.ageGroup }} - {{ team.gender }} / ({{ team.shortName }})
        </h3>
        <div
          class="row"
          v-if="team"
        >
          <div class="col-12 mb-4">
            <small class="text-muted"> This form will invite a user to become a team owner for this team. Enter the invitee information. the email is required.</small>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="submitForm">
                  <!-- Full Name -->

                  <div class="row">
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label
                          for="newownerfirstName"
                          class="form-label"
                          >First Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="profile.newownerfirstName"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label
                          for="newownermiddleName"
                          class="form-label"
                          >Middle Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="profile.newownermiddleName"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label
                          for="newownerlastName"
                          class="form-label"
                          >Last Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="profile.newownerlastName"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <!-- Email Address -->
                      <div class="mb-3">
                        <label
                          for="newowneremail"
                          class="form-label"
                          >Email Address (required)</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="profile.newowneremail"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <!-- Phone Number -->
                      <div class="mb-3">
                        <label
                          for="newownerphoneNumber"
                          class="form-label"
                          >Phone Number</label
                        >
                        <input
                          type="tel"
                          class="form-control"
                          v-model="profile.newownerphoneNumber"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Submit Button -->
                  <button
                    type="submit"
                    class="btn btn-primary"
                  >
                    Send Invite
                    <div
                      class="spinner-border spinner-border-sm ms-2"
                      role="status"
                      v-if="requestLoading"
                    ></div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-5">
        <img
          class="w-100"
          src="../assets/images/right-logo.png"
          alt="Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref, reactive } from "vue";
import { useToast } from "vue-toastification";
import emailjs from "@emailjs/browser";
import { doc, getDoc, collection, getDocs, query, where, setDoc, serverTimestamp } from "firebase/firestore";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth, db, authAdmin } from "../firebase";
import CryptoJS from "crypto-js";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const profile = reactive({
      newownerfirstName: "",
      newownermiddleName: "",
      newownerlastName: "",
      newowneremail: "",
      newownerphoneNumber: "",
    });

    const teamId = route.params.teamId;
    const leagueName = route.query.leagueName;
    const clubName = route.query.clubName;
    const league = ref(route.query.leagueLogo);
    const age = route.query.age;
    const requestLoading = ref(false);
    const emailJsConfig = ref("");
    const team = ref(null);
    const profileCurrent = ref({});

    const fetchData = async () => {
      try {
        const emailJsDoc = query(collection(db, "emailJs"), where("id", "==", "requestConfig"));
        // const teamQuery = query(collection(db, "teams"), where("id", "==", teamId));

        const docRef = doc(db, "teams", teamId);
        const docResult = await getDoc(docRef);
        const emailJsSnapshot = await getDocs(emailJsDoc);

        emailJsSnapshot.forEach((doc) => {
          emailJsConfig.value = doc.data();
        });

        if (!docResult.exists()) {
          console.error("No such team exists!");
          return;
        }

        const teamData = docResult.data();
        team.value = teamData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const submitForm = async () => {
      if (!team.value) {
        console.error("Team information is not available");
        return;
      }
      requestLoading.value = true;
      ///check if the selected person is already owner of the team.
      const profileDoc = query(collection(db, "profiles"), where("email", "==", profile.newowneremail.toLowerCase()));
      const docResult = await getDocs(profileDoc);

      let isAlreadyAddedTeamOwner;
      if (docResult.docs.length) {
        profileCurrent.value = docResult.docs[0].data();
        isAlreadyAddedTeamOwner = Array.isArray(team.value.ownerId) ? team.value.ownerId.includes(docResult.docs[0].id) : docResult.docs[0].id == team.value.ownerId;
      }
      if (isAlreadyAddedTeamOwner) {
        //a person is already owner of the team.
        const toast = useToast();
        toast.error("This person is already an owner of this team");
        requestLoading.value = false;
        return;
      }
      ///check a person has already in the system(Database),but not a team owner.
      if (docResult.docs.length) {
        sendEmailToUserAlreadyInSystem(docResult.docs[0].id, teamId);
      }
      ///handle a person that is not in the system and not a team owner.
      else {
        AddNewUserInSystem();
      }
      // requestLoading.value = false;
    };

    const sendEmailToUserAlreadyInSystem = async (userId, teamId) => {
      if (team.value.name) {
        requestLoading.value = true;
        const teamownerInformation = profileCurrent.value.firstName ? `${profileCurrent.value.firstName} ${profileCurrent.value.lastName}` : auth.currentUser?.email;
        // Encrypt
        var encryptUserId = CryptoJS.AES.encrypt(userId, "user_id").toString();
        var encryptTeamId = CryptoJS.AES.encrypt(teamId, "team_id").toString();

        let templateParams = {
          email: profile.newowneremail.toLowerCase(),
          subject: "You have been invited to become a team owner",
          userUrl: `https://teamsheetpro.com/#/acceptteamowner?teamid=${encryptTeamId}&userid=${encryptUserId}`,
          linkMessage: "Link",
          comment: `You have been invited to become a team owner by ${teamownerInformation}. Follow this link to accept the team ${team.value.name} (${clubName}-${leagueName}-${age}-${team.value.gender}) ownership invite.`,
        };
        let templateAdmin = {
          email: "info@teamsheetpro.com",
          subject: "An invite to become a team owner has been sent",
          userUrl: `https://teamsheetpro.com`,
          linkMessage: "Link",
          comment: `A request from(${auth.currentUser?.email}) to become teamowner of (${team.value.name}) has been sent to ${profile.newownerfirstName} ${profile.newownerlastName} (${profile.newowneremail.toLowerCase()}) to become a teamowner of ${
            team.value.name
          }(${clubName}-${leagueName}-${age}-${team.value.gender})`,
        };
        //send the information email for the current register user
        const response = await emailjs.send("service_SheetProX", "template_8z7lfek", templateParams, "S6E9Jwpf9inCzK0X5");
        if (response.status == 200) {
          Object.keys(profile).forEach((key) => {
            profile[key] = "";
          });
          const toast = useToast();
          toast.success("A request to accept the invite has been sent via email to the recipient.");
        } else {
          console.log("FAILED...");
          toast.error(response.text);
        }
        // //send the information email for the current register user
        await emailjs.send("service_SheetProX", "template_8z7lfek", templateAdmin, "S6E9Jwpf9inCzK0X5");
        requestLoading.value = false;
      }
    };
    const AddNewUserInSystem = async () => {
      requestLoading.value = true;
      // Create the user account without signing them in
      const password = Math.random().toString(36).slice(-8); // generate random password
      try {
        const { user } = await createUserWithEmailAndPassword(
          authAdmin,
          profile.newowneremail.toLowerCase(),
          password // generate random password
        );
        //console.log(user,'user.sendEmailVerification()')
        await sendEmailVerification(user);

        var templateParams = {
          email: profile.newowneremail.toLowerCase(),
          password: password,
          subject: "User Registration",
          userUrl: "https://teamsheetpro.com",
          comment: `The message confirms that ${profile.newownerfirstName} ${profile.newownerlastName} the User has been successfully created.`,
        };
        //send the information email for the current register user
        await emailjs.send("service_SheetProX", "template_requ01", templateParams, "S6E9Jwpf9inCzK0X5");

        if (user) {
          // Create initial profile in Firestore for this user
          await setDoc(doc(db, "profiles", user.uid), {
            firstName: profile.newownerfirstName || "",
            middleName: profile.newownermiddleName || "",
            lastName: profile.newownerlastName || "",
            title: "",
            email: profile.newowneremail.toLowerCase(),
            phone: profile.newownerphoneNumber || "",
            biography: "",
            role: "subscriber",
            createdAt: serverTimestamp(),
          });
          // teamownr
          requestLoading.value = false;
          sendEmailToUserAlreadyInSystem(user.uid, teamId);
        }
      } catch (error) {
        const toast = useToast();

        if (error.code === "auth/email-already-in-use") {
          toast.error("This email is already registered. Please use a different email address.");
        } else if (error.code === "Player with this email already exists in the team.") {
          toast.error("Player with this email already exists in the team.");
        } else {
          console.log("Error creating a user:", error);
          toast.error("Invalid email address.");
        }
      }
    };
    onMounted(() => {
      fetchData();
    });

    return {
      teamId,
      profile,
      requestLoading,
      submitForm,
      router,
      team,
      league,
      leagueName,
      clubName,
    };
  },
};
</script>
